<template>
  <component :is="getActiveComponent"></component>
</template>

<script>
import ResponsiveHeader from "./Responsive";
import DesktopHeader from "./Desktop";

export default {
  name: "Header",

  data() {
    return {
      menus: []
    }
  },

  components: {
    DesktopHeader,
    ResponsiveHeader,
  },

  computed: {
    getActiveComponent(){
      return this.$device.mobile ? 'ResponsiveHeader' : 'DesktopHeader';
    },

    isUserLogin() {
      return this.$store.getters['User/isUserLogin']
    },

    getUserName() {
      return this.$store.getters['User/getUserName']
    },
  },
}
</script>


<style>

</style>