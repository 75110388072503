<template>
  <v-footer class="pt-4">
    <v-container>
      <v-row align="center" justify="center">
        <v-col :cols="12" class="d-flex align-center justify-center">
          <v-btn
              color="main"
              link
              text
              to="/about">
            درباره ما
          </v-btn>
          <v-btn
              color="main"
              link
              text
              to="/rules">
            قوانین و مقررات
          </v-btn>
          <v-btn
              color="main"
              link
              text
              to="/pricing">
            خرید اشتراک
          </v-btn>
        </v-col>

        <v-col :cols="6">
          <v-divider/>
        </v-col>

        <v-col :cols="9">
          <v-row type="flex" align="center" justify="center">
            <v-col :cols="3" class="d-flex align-center justify-center">
              <img width="100" src="/img/samandehi.png"/>
            </v-col>
            <v-col :cols="3" class="d-flex align-center justify-center">
              <img width="100" src="/img/ethadih.png"/>
            </v-col>
            <v-col :cols="3" class="d-flex align-center justify-center">
              <img width="100" src="/img/enamad.png">
            </v-col>
          </v-row>
        </v-col>

        <v-col :cols="12" class="d-flex align-center justify-center">
          <div class="text-body-2 font-weight-bold">
            کپی رایت تمامی حقوق مادی و معنوی این سرویس (وب سایت و اپلیکیشن های موبایل) متعلق به نیوفایل است.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>