<template>
  <component :is="getActiveComponent"></component>
</template>

<script>
import ResponsiveFooter from "./Responsive";
import DesktopFooter from "./Desktop";

export default {
  name: "Footer",

  data() {
    return {
      menus: []
    }
  },

  components: {
    DesktopFooter,
    ResponsiveFooter,
  },

  computed: {
    getActiveComponent(){
      return this.$device.mobile ? 'ResponsiveFooter' : 'DesktopFooter';
    },

    isUserLogin() {
      return this.$store.getters['User/isUserLogin']
    },

    getUserName() {
      return this.$store.getters['User/getUserName']
    },
  },
}
</script>


<style>

</style>