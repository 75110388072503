<template>
  <v-app class="default-layout">
    <Header/>
    <v-main>
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {

  name: 'DefaultLayout',

  components: {
    Header,
    Footer
  }
}
</script>
