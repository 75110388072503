<template>
  <v-app-bar
      app
      dense
      fixed
      color="white"
      elevation="2"
      height="64"
      class="responsive-app-bar w-100"
  >
    <router-link class="d-flex align-center justify-center fill-height w-100" to="/">
      <img style="height: 90%" src="/img/logo.png"/>
    </router-link>
  </v-app-bar>
</template>

<script>
export default {
  name: "ResponsiveHeader",

  data() {
    return {
      menus: []
    }
  },

  computed: {
    isUserLogin() {
      return this.$store.getters['User/isUserLogin']
    },

    getUserName() {
      return this.$store.getters['User/getUserName']
    },
  },
}
</script>


<style>


</style>