<template>
  <v-app-bar
      class="header-desktop"
      app
      color="white"
      elevation="2"
      height="64"
  >
    <v-container class="py-0 fill-height">
      <router-link to="/" class="h-100 ml-8">
        <img class="h-100" src="/img/logo.png"/>
      </router-link>
      <ul class="header-desktop__nav-container">
        <li v-for="(menu,index) in menus"
            :key="index"
        >
          <router-link
              exact
              :to="menu.link"
              active-class="main--text"
              class="d-flex align-center justify-start"
          >
            <v-icon>
              {{ menu.icon }}
            </v-icon>
            {{ menu.name }}
          </router-link>
        </li>

      </ul>

      <v-spacer></v-spacer>


      <div>
        <v-btn
            outlined
            v-if="!isUserLogin"
            class="d-flex align-center justify-center"
            link
            to="/auth/login"
            text
            large
            color="main"
        >
          <v-icon>
            mdi-account
          </v-icon>

          ورود | ثبت نام

        </v-btn>

        <v-btn
            color="primary"
            v-else
            class="d-flex align-center justify-center"
            link
            to="/panel/profile"
            outlined
            text
            large
        >
          <v-icon>
            mdi-account
          </v-icon>

          {{ getUserName }}

        </v-btn>
      </div>


    </v-container>

  </v-app-bar>
</template>

<script>
export default {
  name: "DesktopHeader",

  data() {
    return {
      menus: [
        {
          link: '/',
          name: 'صفحه اصلی',
          icon: 'mdi-home-outline'
        },
        {
          link: '/files/search',
          name: 'جستجو فایل',
          icon: 'mdi-clipboard-text-search-outline'
        },
        {
          link: '/pricing',
          name: 'خرید اشتراک',
          icon: 'mdi-gift-outline'
        },
        {
          link: '/rules',
          name: 'قوانین و مقررات',
          icon: 'mdi-file-document'
        },
      ]
    }
  },

  computed: {
    isUserLogin() {
      console.log(this.$store.getters['User/isUserLogin'])
      return this.$store.getters['User/isUserLogin']
    },

    getUserName() {
      return this.$store.getters['User/getUserName']
    },
  },
}
</script>


<style>
.header-desktop__nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-desktop__nav-container li:hover {
  color: var(--v-main-base) !important;
}

.header-desktop__nav-container li .v-icon {
  color: inherit !important;
}

.header-desktop__nav-container li a {
  padding: 5px 15px;
  font-size: 16px;
  color: inherit;
}

</style>